import { Routes, Route } from 'react-router-dom';
import Main from './Main';
import Guide from './Guide';
import { useEffect } from 'react';
import { init } from "@amplitude/analytics-browser";

function App() {
  useEffect(() => {
    init("be9b8b281c4f177f748eb113dfa6cc68");
  })

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/guide" element={<Guide />} />
    </Routes>
  );
}

export default App;