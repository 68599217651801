import logo from './logo.svg';
import {useState, useEffect} from 'react';

import "react-notion/src/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import queryString from 'query-string';

import Nav from './Header';

function Guide() {
  const [response, setResponse] = useState("");

  let qs = queryString.parse(window.location.search);
  let utmTag = Object.keys(qs).length == 0 ? "" : "?" + queryString.stringify(qs);

  useEffect(() => {
    const NOTION_PAGE_ID = '2e918b21046d4099b8612c978da02677';
    fetch(`https://notion.postalpine.workers.dev/v1/page/${NOTION_PAGE_ID}`, {
      method: "GET",
      headers: {
        'Content-type': 'application/json'
      },
    }).then(
      (data) => {
        data.json().then(json => {
          console.log(json);
          setResponse(json);
        })
      });
    }, []);

  return (
    <div className="App">
      <Nav/>
        {
          <NotionRenderer blockMap={response} fullPage={true} />
        }
    </div>
  );
}

export default Guide;
