import logo from './logo.svg';
import {useState, useEffect} from 'react';

import "react-notion/src/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import queryString from 'query-string';
import { track } from "@amplitude/analytics-browser";

import Nav from './Header';

function Main() {
  const [response, setResponse] = useState({});

  let qs = queryString.parse(window.location.search);
  let utmTag = Object.keys(qs).length == 0 ? "" : "?" + queryString.stringify(qs);

  useEffect(() => {
    const NOTION_PAGE_ID = 'ccfa9b565b2245f5bbc5e3015deb33ef';
    fetch(`https://notion.postalpine.workers.dev/v1/page/${NOTION_PAGE_ID}`, {
      method: "GET",
      headers: {
        'Content-type': 'application/json'
      },
    }).then(
      (data) => {
        data.json().then(json => {
          console.log(json);
          setResponse(json);
        })
      });
    }, []);

  return (
    <div className="App">
      <Nav/>
        {
          <NotionRenderer blockMap={response} fullPage={true} />
        }
        <a className="fab d-none d-md-block" href="https://forms.gle/8j1snR2CAtciPxvj9" onClick={()=>{track("seller_click_form");}}>
          지금 입점하고 수수료 50% 할인받기 (13 / 40 마감)
        </a>
        <a className="fab fab-mobile d-xs-block d-sm-block d-md-none" href="https://forms.gle/8j1snR2CAtciPxvj9" onClick={()=>{track("seller_click_form");}}>
          지금 입점하고 수수료 50% 할인받기 (13 / 40 마감)
        </a>
    </div>
  );
}

export default Main;
